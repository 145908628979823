import React, { useEffect, useRef, useState } from 'react';
import './index.scss';

import logoHorizontal from '../../assets/logoHorizontal.png';
import menu from '../../assets/menu.png';

const Navbar = ({ aboutUsRef, advantagesRef, contactRef }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const navbarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if(navbarRef?.current && !navbarRef.current.contains(e.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, [ navbarRef, isOpen ]);

  const onClickLink = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
    setIsOpen(false);
  };

  return (
    <div className="navbar" ref={navbarRef}>
      <img src={logoHorizontal} alt="logo"></img>
      <div className={`links ${isOpen ? 'open': ''}`}>
        <a href='https://share-eu1.hsforms.com/1u8OG-IRbRT2qNAoNEu0ZHg2b3eo2' target='_blank' rel="noreferrer"> ¡Quiero Asociarme!</a>
        <button onClick={() => onClickLink(aboutUsRef)}>Quienes Somos</button>
        <button onClick={() => onClickLink(advantagesRef)}>Ventajas</button>
        <button onClick={() => onClickLink(contactRef)}>Contacto</button>
      </div>
      <button className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
        <img src={menu} alt="menu-logo"></img>
      </button>
    </div>
  )
}

export default Navbar
