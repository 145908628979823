import React, { useState } from 'react';
import axios from 'axios';
import './index.scss';

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const Contact = () => {
  const [ loading, setLoading ] = useState(false);
  const [ emailSent, setEmailSent ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ form, setForm ] = useState({});
  const sendEmail = () => {
    let error = false;
    if(!form.name) {
      error = true;
    }
    if(!form.email || !isValidEmail(form?.email)) {
      error = true;
    }
    if(!error) {
      setLoading(true);
      const data = JSON.stringify({
        adminEmail: 'ajebalears@gmail.com',
        email: form?.email,
        name: form?.name,
        phone: form?.telephone,
        body: form?.message,
      });
      axios.post('https://m51juqngr0.execute-api.us-east-1.amazonaws.com/dev/contactForm', data).then(() => {
        setEmailSent(true);
      })
      .finally(() => {
        setLoading(false);
      });
    }
    setShowError(error);
  };
  const changeForm = (key, e) =>{
    setForm({ ...form, [key]: e?.target?.value});
    setShowError(false);
  };
  return (
    <div className="contact">
      <h1>¿En que podemos ayudarte? ¡Escríbenos y te contestaremos lo antes posible!</h1>
      <div className="form">
        <input
          type="text"
          placeholder="Nombre Completo*"
          value={form?.name}
          onChange={(e) => changeForm('name', e)}
        />
        <input
          type="tel"
          placeholder="Teléfono"
          value={form?.telephone}
          onChange={(e) => changeForm('telephone', e)}
        />
        <input
          type="email"
          placeholder="Email*"
          value={form?.email}
          onChange={(e) => changeForm('email', e)}
        />
        <input
          type="text"
          placeholder="Mensaje"
          value={form?.message}
          onChange={(e) => changeForm('message', e)}
        />
        {!emailSent ? <button disabled={loading} onClick={sendEmail}>{loading ? '...' : 'Enviar'}</button> : <div className="thanks">¡Gracias!</div>}
        {showError && <div className="error">Hay algún error en los datos. Revíselos.</div>}
      </div>
    </div>
  )
}

export default Contact;
