import React from 'react';
import './index.scss';

const AboutUs = ({ aboutUsRef }) => {
  return (
    <div ref={aboutUsRef} className="about-us">
      <div className="header">
        <h1>¿Quienes Somos?</h1>
        <p>Somos una asociación sin ánimo de lucro fundada en 1994. Independinete de la administración pública, de organizaciones de trabajadores y de partidos políticos.</p>
        <p>La mayor asociación de jóvenes empresas multisectorial de la provincia y la única en su segmento, como representante de los jóvenes empresarios y empresarias.</p>
        <p className="question">¿Qué hacemos?</p>
        <p>Representamos a los empresarios, asesoramos gratuitamente a emprendedores y apoyamos e impulsamos el crecimiento de nuestros asociados.</p>
        <p className="question">¿Qué requisitos necesitas cumplir para estar en AJE?</p>
        <p>Tener una empresa o estar dado de alta como autónomo/a</p>
        <p className="question">¿Es necesario ser joven para formar parte de AJE?</p>
        <p>Representamos a las jóvenes empresas, empresarias y empresarios, más allá de su edad; solo que si tienes menos de 45 años puedes formar parte de nuestra directiva.</p>
      </div>
    </div>
  )
}

export default AboutUs
