import React from 'react';
import './index.scss';

const points = [
  {
    title: "Asesoramiento",
    description: "Te informamos sobre las posiblidades de gestión de tu empresa, ofreciéndote un plan para desarrollarte como empresario. Te apoyamos sea cual sea el punto en el que se encuentra tu empresa. Somos voz para tu empresa a través de nuestras redes sociales."
  },
  {
    title: "Representación",
    description: "Trasladamos tus reivindicaciones y necesidades a las Administraciones públicas, así como a cualquier otro organismo o entidad que necesites."
  },
  {
    title: "Negocio",
    description: "Con nuestra amplia red de contactos te buscamos más negocio, aumentamos tu cartera de clientes aprovechando empresas asociadas a AJE, a nivel provincial, regional y nacional."
  },
  {
    title: "Formación",
    description: "A través de nuestro talleres, jornadas, etc... podrás formarte de manera gratuita y mejorar tu competitividad."
  },
  {
    title: "Convenios",
    description: "Aprovecha todas las ventajas de ser AJE en multitud de sectores como: salud, automoción financiero, telefonía y comunicación, energéticos, aseguradoras. Ponte en contacto con nosotros y te detallamos todos los beneficios que puedes obtener por ser socio."
  },
  {
    title: "Promoción",
    description: "Da a conocer tu empresa a través de nuestras redes sociales, circulares de ofertas para socios y boletín de eventos."
  },
  {
    title: "Información",
    description: "Te mantenemos al tanto de toda la actualidad de tu sector."
  },
]

const Advantages = ({ advantagesRef }) => {
  return (
    <div ref={advantagesRef} className="advantages">
      <h1>¿Qué podemos hacer por ti?</h1>
      <div className="header">
        <div className="points">
          {points.map((point, idx) =>(
          <div key={idx} className="point-wrapper">
            <p className="title">{point.title}</p>
            <p>{point.description}</p>
          </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Advantages
