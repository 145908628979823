import React from 'react';
import './index.scss';

import caeb from '../../../assets/caeb.jpg';
import ceoe from '../../../assets/ceoe.svg';
import cepyme from '../../../assets/cepyme.png';

const partners = [
  {
    logo: caeb,
    url: 'https://www.caeb.es'
  },
  {
    logo: ceoe,
    url: 'https://www.ceoe.es'
  },
  {
    logo: cepyme,
    url: 'https://www.cepyme.es'
  },
]

const Partners = () => {
  return (
    <div className="partners">
      <div className="header">
        <h1>Con el apoyo de:</h1>
        <div className="partners-wrapper">
          {partners.map((partner, idx) =>(
          <a href={partner.url} target="_blank" rel="noreferrer" key={idx} className="partner-wrapper">
            <img src={partner.logo} alt={`logo`}></img>
          </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Partners;
