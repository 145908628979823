import { useRef } from 'react';
import Footer from '../../components/Footer';
import AboutUs from '../../components/Homepage/AboutUs';
import Advantages from '../../components/Homepage/Advantages';
import Contact from '../../components/Homepage/Contact';
import Partners from '../../components/Homepage/Partners';
import TopSection from '../../components/Homepage/TopSection';
import Navbar from '../../components/Navbar';
import './index.scss';

const Homepage = () => {
  const aboutUsRef = useRef(); 
  const advantagesRef = useRef(); 
  const contactRef = useRef(); 
  return (
    <div className="homepage">
      <Navbar aboutUsRef={aboutUsRef} advantagesRef={advantagesRef} contactRef={contactRef}/>
      <TopSection contactRef={contactRef}/>
      <AboutUs aboutUsRef={aboutUsRef}/>
      <div className="separator"></div>
      <Advantages advantagesRef={advantagesRef}/>
      <Partners/>
      <Contact/>
      <Footer contactRef={contactRef}/>
    </div>
  );
}

export default Homepage;
