import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import image1 from '../../../assets/antenna-FDV1BaKNKEo-unsplash-min.jpg';
import image2 from '../../../assets/product-school-nOvIa_x_tfo-unsplash-min.jpg';
import image3 from '../../../assets/samantha-gades-fIHozNWfcvs-unsplash-min.jpg';

const images = [
image1,
image2,
image3
]

const TopSection = ({ contactRef }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSelectedImage((prev) => {
        if(prev === (images?.length - 1)) {
          return 0;
        }
        return prev + 1;
      });
    }, 6000);
    return () => {
      clearInterval(intervalRef.current);
    }
  }, []);

  return (
    <div className="top-section">
      <div className="carousel" style={{ width: `${images?.length}00vw`, transform: `translateX(-${selectedImage * window.innerWidth}px)`}}>
        {images.map((image, idx) =>
          <div key={idx} className="image-wrapper" style={{ backgroundImage: `url(${image})`}}/>
        )}
      </div>
      <div className="text">
        <h1>¿Eres empresario y tienes menos de 45 años?</h1>
        <button onClick={() => contactRef?.current?.scrollIntoView({ behavior: 'smooth' })}>¡Te estamos buscando!</button>
      </div>
    </div>
  )
}

export default TopSection
