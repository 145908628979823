import React from 'react';
import './index.scss';

const Footer = ({ contactRef }) => {
  return (
    <div ref={contactRef} className="footer">
      <div className="row">
        <a href="mailto:ajebalears@gmail.com">info@ajebalears.com</a>
      </div>
      <div className="row">
        <a href='tel:0034689893980'>+34 689 89 39 80</a>
      </div>
      <div className="row">
        <p>Copyright {new Date().getFullYear()}. Todos los derechos reservados.</p>
      </div>
      <div className="developer">
        <p>Diseño y desarrollo por</p>
        <a href="https://wozzo.es" target="_blank" rel="noreferrer">Wozzo Software</a>
      </div>
    </div>
  )
}

export default Footer
